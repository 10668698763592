<template>
  <div class="sect_menubar">
    <!-- 리뷰 유형 1 -->
    <ul class="menubar depth_4 line">
      <li :class="{ on: parseInt(defaultOp1) === parseInt(producer) }">
        <router-link to="" @click.native="changeMenu('1', '0')"
          >생산자 R</router-link
        >
      </li>
      <li :class="{ on: parseInt(defaultOp1) === parseInt(seller) }">
        <router-link to="" @click.native="changeMenu('1', '3')"
          >판매자 R</router-link
        >
      </li>
      <li
        v-for="op1 in option1Data"
        :key="op1.st1_seq"
        :class="{ on: parseInt(op1.st1_seq) === parseInt(defaultOp1) }"
      >
        <router-link to="" @click.native="changeMenu('1', op1.st1_seq)">{{
          op1.st1_title.replace("리뷰", "R")
        }}</router-link>
      </li>
    </ul>
    <!-- -->

    <!--판매자,소비자-->
    <ul
      class="menubar depth_5 line"
      v-if="
        parseInt(defaultOp1) === parseInt(normal) ||
        parseInt(defaultOp1) === parseInt(seller)
      "
    >
      <li :class="{ on: parseInt(defaultOp2) === 0 }">
        <router-link to="" @click.native="changeMenu('2', '0')"
          >전체 R</router-link
        >
      </li>
      <li
        v-for="op2 in option2Data"
        :key="op2.st2_seq"
        :class="{ on: parseInt(op2.st2_seq) === parseInt(defaultOp2) }"
      >
        <router-link to="" @click.native="changeMenu('2', op2.st2_seq)">{{
          op2.st2_title
        }}</router-link>
      </li>
    </ul>
    <!-- -->

    <!--전문가-->
    <ul
      class="menubar depth_4 line"
      v-if="parseInt(defaultOp1) === parseInt(pro)"
    >
      <li :class="{ on: parseInt(defaultOp2) === 0 }">
        <router-link to="" @click.native="changeMenu('2', '0')"
          >전체 R</router-link
        >
      </li>
      <li
        v-for="op3 in option3Data"
        :key="op3.st2_seq"
        :class="{ on: parseInt(op3.st2_seq) === parseInt(defaultOp2) }"
      >
        <router-link to="" @click.native="changeMenu('2', op3.st2_seq)">{{
          op3.st2_title
        }}</router-link>
      </li>
    </ul>
    <!-- -->

    <!--생산자-->
    <ul
      class="menubar depth_2 line"
      v-if="parseInt(defaultOp1) === parseInt(producer)"
    >
      <li :class="{ on: parseInt(defaultOp2) === 0 }">
        <router-link to="" @click.native="changeMenu('2', '0')"
          >구매 가능</router-link
        >
      </li>
      <li :class="{ on: parseInt(defaultOp2) === -1 }">
        <router-link to="" @click.native="changeMenu('2', '-1')"
          >구매 불가능</router-link
        >
      </li>
    </ul>
    <!-- -->

    <!-- 사용 기간 -->
    <ul class="menubar depth_5 line">
      <li :class="{ on: parseInt(defaultOp3) === 0 }">
        <router-link to="" @click.native="changeMenu('3', '0')"
          >전체 R</router-link
        >
      </li>
      <li
        v-for="op4 in option4Data"
        :key="op4.st2_seq"
        :class="{ on: parseInt(op4.st2_seq) === parseInt(defaultOp3) }"
      >
        <router-link to="" @click.native="changeMenu('3', op4.st2_seq)">{{
          op4.st2_title
        }}</router-link>
      </li>
    </ul>
    <!-- -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      normal: 1,
      pro: 2,
      producer: 0,
      seller: 3,
      termSeq: 10,
      consumerSeq: 1,
      expertSeq: 2,
      defaultOp1: this.option1,
      defaultOp2: this.option2,
      defaultOp3: this.option3,
    };
  },
  props: {
    reviewGoodsMenu: {
      type: Array,
      default: () => [],
    },
    option1: {
      type: Number,
      default: 1,
    },
    option2: {
      type: Number,
      default: 3,
    },
    option3: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    option1Data() {
      return this.reviewGoodsMenu.filter(
        (menu) =>
          menu.st1_title && parseInt(menu.st1_seq) !== parseInt(this.termSeq)
      );
    },
    option2Data() {
      let data = [];
      this.reviewGoodsMenu.map((menu) => {
        if (parseInt(menu.st1_seq) === parseInt(this.consumerSeq)) {
          Object.keys(menu).forEach((key) => {
            if (menu[key].st2_title) {
              data.push(menu[key]);
            }
          });
        }
      });
      return data;
    },
    option3Data() {
      let data = [];
      this.reviewGoodsMenu.map((menu) => {
        if (parseInt(menu.st1_seq) === parseInt(this.expertSeq)) {
          Object.keys(menu).forEach((key) => {
            if (menu[key].st2_title) {
              data.push(menu[key]);
            }
          });
        }
      });
      return data;
    },
    option4Data() {
      let data = [];
      this.reviewGoodsMenu.map((menu) => {
        if (parseInt(menu.st1_seq) === parseInt(this.termSeq)) {
          Object.keys(menu).forEach((key) => {
            if (menu[key].st2_title) {
              data.push(menu[key]);
            }
          });
        }
      });
      return data;
    },
  },
  methods: {
    changeMenu(level, value, emit = true) {
      if (parseInt(level) === 1) {
        this.defaultOp1 = value;
        if (parseInt(value) === parseInt(this.producer)) {
          this.defaultOp2 = "0"; //생산자 구매가능
        } else if (
          parseInt(value) === parseInt(this.normal) ||
          parseInt(value) === parseInt(this.seller)
        ) {
          this.defaultOp2 = "0"; //소비자, 판매자 (전체)
        } else if (parseInt(value) === parseInt(this.pro)) {
          this.defaultOp2 = "0"; //전문가  (전체)
        }
        this.defaultOp3 = "0";
      } else if (parseInt(level) === 2) {
        this.defaultOp2 = value;
        this.defaultOp3 = "0";
      } else if (parseInt(level) === 3) {
        this.defaultOp3 = value;
      }

      if (emit) {
        this.$emit(
          "changOption",
          this.defaultOp1,
          this.defaultOp2,
          this.defaultOp3
        );
      }
    },
  },
};
</script>

<style></style>
