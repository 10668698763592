<template>
  <div class="box">
    <div class="box_1">
      <div class="top">
        <div class="img">
          <img
            :src="item.thumbnail"
            alt=""
            @error="replaceImg"
            @click="openDetail()"
          />
          <span>{{ index }}</span>
        </div>
        <div class="txt">
          <h3 @click="openDetail()">
            {{ item.title }}
          </h3>
          <p @click="openDetail()">상품명 : {{ item.main_goods_name }}</p>
        </div>
      </div>
      <ul class="bottom">
        <template v-if="parseInt(item.option_category1) !== 3">
          <li>
            <router-link
              to=""
              @click.native="
                likeCheck({
                  wish_yn: item.wish_yn_reviwer,
                  reviewer_seq: item.member_seq,
                  review_seq: null,
                  main_seq: item.main_menu_seq,
                  type: 'reviewer',
                })
              "
            >
              <img
                v-if="item.wish_yn_reviwer == 'Y'"
                src="@/assets/images/sub/like_on.png"
                alt="" />
              <img
                v-else
                src="@/assets/images/sub/like_off.png"
                alt="" /></router-link
            ><router-link to="">{{ item.nickname }}</router-link>
          </li>
        </template>
        <template v-else>
          <li>
            <router-link
              to=""
              @click.native="
                likeShop({
                  wish_yn: item.mini_shop_wish,
                  reviewer_seq: null,
                  review_seq: item.provider_seq,
                  main_seq: item.main_menu_seq,
                  type: 'shop',
                })
              "
            >
              <img
                v-if="item.mini_shop_wish == 'Y'"
                src="@/assets/images/sub/like_on.png"
                alt="" />
              <img
                v-else
                src="@/assets/images/sub/like_off.png"
                alt="" /></router-link
            ><router-link to="">{{ item.nickname }}</router-link>
          </li>
        </template>
        <li>리뷰 신뢰도 {{ rammFloat(item.trust_score) }}</li>
        <li>제품 평점 {{ item.main_score }}</li>
        <li>
          <router-link
            to=""
            :id="`arrow_${item.seq}`"
            @click.native="openDetail()"
            >∨</router-link
          >
        </li>
      </ul>
    </div>
    <div
      class="box_2 detail"
      style="display: none"
      :id="`item_detail_${item.seq}`"
    >
      <h3>
        {{ item.title }}
      </h3>
      <!--상품-일반/특정-->
      <div class="top_1" v-if="parseInt(item.option_category1) === 1">
        <div
          class="left"
          v-if="
            myInfo && parseInt(myInfo.member_seq) === parseInt(item.member_seq)
          "
        >
          <template v-if="parseInt(item.member_seq) === parseInt(isReviewer)">
            <router-link to="" @click.native="modifyReview('normal')"
              >수정</router-link
            >&nbsp;&nbsp;|&nbsp;&nbsp;<router-link
              to=""
              @click.native="deleteReview"
              >삭제</router-link
            >
          </template>
        </div>
        <div class="right">
          <b
            >구매자들의 리뷰 신뢰도&nbsp;&nbsp;{{
              rammFloat(item.trust_score)
            }}</b
          ><br />리뷰어의 제품 평점&nbsp;&nbsp;{{ item.main_score }}
        </div>
      </div>
      <!--상품-전문가-->
      <div class="top_2" v-if="parseInt(item.option_category1) === 2">
        <div
          class="left"
          v-if="
            myInfo && parseInt(myInfo.member_seq) === parseInt(item.member_seq)
          "
        >
          <template
            v-if="
              parseInt(item.member_seq) === parseInt(isReviewer) &&
              channel.reviewer
            "
          >
            <router-link to="" @click.native="modifyReview('pro')"
              >수정</router-link
            >&nbsp;&nbsp;|&nbsp;&nbsp;<router-link
              to=""
              @click.native="deleteReview"
              >삭제</router-link
            >
          </template>
        </div>
        <div class="right">
          <b
            >구매자들의 리뷰 신뢰도&nbsp;&nbsp;{{
              rammFloat(item.trust_score)
            }}</b
          >
        </div>
      </div>
      <!-- 판매자-->
      <div class="top_1" v-if="parseInt(item.option_category1) === 3">
        <div
          class="left"
          v-if="
            myInfo && parseInt(myInfo.member_seq) === parseInt(item.member_seq)
          "
        >
          <template v-if="parseInt(item.member_seq) === parseInt(isSeller)">
            <router-link to="" @click.native="modifyReview('seller')"
              >수정</router-link
            >&nbsp;&nbsp;|&nbsp;&nbsp;<router-link
              to=""
              @click.native="deleteReview"
              >삭제</router-link
            >
          </template>
        </div>
        <div class="right">
          <b
            >구매자들의 리뷰 신뢰도&nbsp;&nbsp;{{
              rammFloat(item.trust_score)
            }}</b
          ><br />리뷰어의 제품 평점&nbsp;&nbsp;{{ item.main_score }}
        </div>
      </div>

      <!--상품-전문가-->
      <div class="grade" v-if="parseInt(item.option_category1) == 2">
        <div class="left">리뷰어의 제품 평점</div>
        <div class="right">
          <div>
            <span class="txt">A({{ item.main_goods_name }})</span
            ><span class="num">{{ item.main_score }}</span>
          </div>
          <template v-for="(compare, index) in compareGoods(item)">
            <div :key="index">
              <span class="txt"
                >{{ parseInt(index) < 1 ? "B" : "C" }} ({{
                  compare.goods_name
                }})</span
              >
              <span class="num">{{ compare.score }}</span>
            </div>
          </template>
        </div>
      </div>

      <div class="middle" v-html="item.content">
        {{ item.content }}
      </div>
      <!---->
      <div class="rn_ms_empty_20"></div>
      <!---->
      <div class="btn_2">
        <router-link
          to=""
          class="left black"
          @click.native="
            likeCheck({
              wish_yn: item.wish_yn,
              review_seq: item.seq,
              reviewer_seq: null,
              main_seq: item.main_menu_seq,
              type: 'review',
            })
          "
          >♡ 이 리뷰 찜하기</router-link
        >
        <router-link to="" @click.native="openDeclaration()" class="right white"
          >신고하기</router-link
        >
        <router-link to="" class="right gray" @click.native="openPopSns()"
          >SNS공유하기</router-link
        >
        <ReviewSnsShare
          :item="item"
          :nowPage="pageUrl"
          :ref="`sns_share_${item.seq}`"
          :id="`sns_share_${item.seq}`"
        />
        <ReviewDeclaration
          :item="item"
          :id="`declaration_${item.seq}`"
          style="display: none"
        />
      </div>
      <div class="reply_btn" @click="openReply()">
        <router-link to=""
          >댓글 {{ item.comment_cnt }}개
          <span :id="`arrow_reply_${item.seq}`" class="arrow_reply"
            >∨</span
          ></router-link
        >
      </div>
      <!---->
      <div class="rn_ms_empty_20"></div>
      <!---->
      <ReviewReply
        :id="`reply_view_${item.seq}`"
        :item="item"
        :replyListArray="replyListArray"
        :page="parseInt(replyPage)"
        :ref="`reply_view_${item.seq}`"
        @setSort="setSort"
        @getReplyList="getReplyList"
        class="reply_list_box"
        style="display: none"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ReviewSnsShare from "@/components/review/state/ReviewSnsShare";
import ReviewDeclaration from "@/components/review/state/ReviewDeclaration";
import ReviewReply from "@/components/review/state/ReviewReply";
export default {
  data() {
    return {
      reviewTmpScore: 0,
      tmPscore: 0,
      orgReviewScore: this.item.review_score,
      orgScore: this.item.score,
      replyPage: 0,
      sort: "DESC",
      goodsMenu: 1,
    };
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    category: {
      type: String,
      default: "",
    },
    nowPage: {
      type: String,
      default: "",
    },
    index: {
      type: Number,
      default: 0,
    },
    goods: {
      type: Boolean,
      default: false,
    },
    paramsQuery: {
      type: String,
      default: "",
    },
  },
  methods: {
    replaceImg(e) {
      if (this.item.goods_img) {
        e.target.src = this.item.goods_img;
      } else {
        e.target.src = require("@/assets/images/newsNoimg.png");
      }
    },
    needsLogin() {
      if (!this.$store.state.member.isLogin) {
        this.$confirm("로그인이 필요합니다.")
          .then(() => {
            this.$store.dispatch("common/setReferer", {
              path: this.$route.path,
            });
            this.$router.push("/member/login");
            return false;
          })
          .catch(() => {
            return false;
          });
      } else {
        return true;
      }
    },
    async likeCheck(params) {
      if (!this.needsLogin()) return false;

      if (params.wish_yn === "Y") {
        params.wish_yn = "N";
      } else {
        params.wish_yn = "Y";
      }

      await this.$store.dispatch("wish/updateWish", params);
      this.$toast.default(this.$store.state.wish.msg);
      if (this.$store.state.wish.result) {
        this.$store.dispatch("review/updateWish", {
          seq: params.review_seq,
          reviewer: params.reviewer_seq,
          wishYn: params.wish_yn,
          type: params.type,
        });
      }
      // if (params.type === "review") {
      //   this.$router.push(`/wish/review?menu=${this.goodsMenu}`);
      // }
    },
    async likeShop(params) {
      if (!this.needsLogin()) return false;

      if (params.wish_yn === "Y") {
        params.wish_yn = "N";
      } else {
        params.wish_yn = "Y";
      }
      //reviewer_seq 파라메터는 상황에 따라 리뷰어, 리뷰, 가게 번호가 됨.
      await this.$store.dispatch("wish/updateWishShop", {
        reviewer_seq: params.review_seq,
        wish_yn: params.wish_yn,
      });
      //reviewer, seq 파라메터는 상황에 따라 리뷰어, 리뷰, 가게 번호가 됨.
      this.$toast.default(this.$store.state.wish.msg);
      if (this.$store.state.wish.result) {
        this.$store.dispatch("review/updateWish", {
          seq: params.review_seq,
          reviewer: params.reviewer_seq,
          wishYn: params.wish_yn,
          type: params.type,
        });
      }
    },
    rammFloat(str) {
      return this.$rammFloat(str);
    },
    openDetail() {
      const detail = document.querySelector(`#item_detail_${this.item.seq}`);
      const arrow = document.querySelector(`#arrow_${this.item.seq}`);
      const box2 = document.querySelectorAll(".box_2.detail");
      const video = document.querySelector(
        `#item_detail_${this.item.seq} .ql-video`
      );

      if (video) {
        video.style.width = "100%";
        const src = `${video.src}&enablejsapi=1`;
        const index = video.src
          .split("&")
          .findIndex((s) => s === "enablejsapi=1");
        if (index === -1) {
          video.src = src;
        }
      }
      this.resetDetail(); //초기화
      [...box2].map((box) => {
        if (box !== detail) box.style.display = "none";
      });
      if (detail.style.display === "none") {
        detail.style.display = "block";
        arrow.textContent = "∧";
        this.$store.dispatch("review/setViewHit", {
          review_seq: this.item.seq,
        });
        this.$emit("setSeq", this.item.seq);
      } else {
        if (video) {
          video.contentWindow.postMessage(
            '{"event":"command","func":"' + "stopVideo" + '","args":""}',
            "*"
          );
        }
        detail.style.display = "none";
        arrow.textContent = "∨";
        this.$emit("setSeq", "0");
      }
    },
    resetDetail() {
      // 상세 페이지가 열릴 떄 마다 초기화
      this.item.review_score = this.orgReviewScore;
      this.item.score = this.orgScore;
      this.reviewTmpScore = 0;
      this.tmPscore = 0;
      const snsClass = document.querySelectorAll(".sns_box");
      const declPop = document.querySelectorAll(".declaration_pop");
      const replyList = document.querySelectorAll(".reply_list_box");
      const arrowReply = document.querySelector(
        `#arrow_reply_${this.item.seq}`
      );
      // const infoWord = document.querySelectorAll(".info_word");
      // const arrowInfo = document.querySelector(`#arrow_info_${this.item.seq}`);

      // [...infoWord].map((word) => {
      //   word.style.display = "none";
      // });
      // arrowInfo.textContent = "∨";

      [...snsClass].map((sns) => {
        sns.style.display = "none";
      });
      [...declPop].map((decl) => {
        decl.style.display = "none";
      });
      [...replyList].map((reply) => {
        reply.style.display = "none";
      });
      arrowReply.textContent = "∨";
    },
    openInfo() {
      const arrowInfo = document.querySelector(`#arrow_info_${this.item.seq}`);
      const arrowInfoWord = document.querySelector(
        `#arrow_info_word_${this.item.seq}`
      );

      const infoWord = document.querySelectorAll(".info_word");
      [...infoWord].map((word) => {
        if (word !== arrowInfoWord) word.style.display = "none";
      });
      if (arrowInfoWord.style.display === "none") {
        arrowInfoWord.style.display = "block";
        arrowInfo.textContent = "∧";
      } else {
        arrowInfoWord.style.display = "none";
        arrowInfo.textContent = "∨";
      }
    },
    openDeclaration() {
      if (!this.needsLogin()) return false;

      const allPop = document.querySelectorAll(".declaration_pop");
      const pop = document.querySelector(`#declaration_${this.item.seq}`);

      [...allPop].map((ap) => {
        if (ap !== pop) {
          ap.style.display = "none";
        }
      });
      if (pop.style.display === "none") {
        pop.style.display = "block";
      } else {
        pop.style.display = "none";
      }
    },
    compareGoods(item) {
      let data = [];
      let goods_name = item.compare_goods_name.split("|");
      let score = item.compare_score.split(",");
      let seq = item.compare_goods_seq.split(",");

      goods_name.forEach((name, index) => {
        data.push({
          goods_name: name,
          score: score[index],
          seq: seq[index],
        });
      });
      return data;
    },
    openPopSns() {
      this.$refs[`sns_share_${this.item.seq}`].openPopSns(this.item.seq);
    },
    openReply() {
      const replyEl = document.querySelector(`#reply_view_${this.item.seq}`);
      const arrowReply = document.querySelector(
        `#arrow_reply_${this.item.seq}`
      );
      const reRePlyList = document.querySelectorAll(".re_reply_box");
      [...reRePlyList].map((list) => {
        list.style.display = "none";
      });
      [...document.querySelectorAll(".replyArrow")].map(
        (re) => (re.textContent = "∨")
      );
      const replyList = document.querySelectorAll(".reply_list_box");
      [...replyList].map((reply) => {
        if (reply !== replyEl) {
          reply.style.display = "none";
        }
      });

      if (replyEl.style.display === "none") {
        replyEl.style.display = "block";
        arrowReply.textContent = "∧";

        if (
          this.$store.state.member.isLogin &&
          this.item.review_score !== null &&
          this.item.score !== null
        ) {
          this.$refs[`reply_view_${this.item.seq}`].isCheckAuth = true;
        }
        this.getReplyList(true);
      } else {
        replyEl.style.display = "none";
        arrowReply.textContent = "∨";
      }
    },
    setSort(sort) {
      this.sort = sort;
      this.getReplyList(true);
    },
    async getReplyList(reset = false) {
      if (reset) {
        this.replyPage = 0;
      }
      await this.$store.dispatch("review/getReplyList", {
        seq: this.item.seq,
        page: this.replyPage,
        sort: this.sort,
        reset,
      });
      if (!this.result) {
        this.$toast.default(this.msg);
      }
      this.replyPage++;
    },
    reviewScore(score) {
      if (score) {
        return score;
      }
      return 0;
    },
    evaluationScore(score) {
      if (score) {
        return score;
      }
      return 0;
    },
    deleteReview() {
      this.$confirm("해당 리뷰를 삭제하시겠습니까?")
        .then(async () => {
          await this.$store.dispatch("review/deleteReview", {
            seq: this.item.seq,
          });
          this.$toast.default(this.msg);
        })
        .catch(() => {
          return false;
        });
    },
    modifyReview(type) {
      const query = `?${this.paramsQuery}&seq=${this.item.seq}&mode=modify`;
      // console.log(query);

      if (type === "normal") {
        this.$router.push(
          `/review/review_normal_goods_write/${this.category}/${this.item.seq}${query}`
        );
      } else if (type === "pro") {
        this.$router.push(`/review/review_pro_goods_write${query}`);
      } else if (type === "seller") {
        this.$router.push(
          `/review/review_seller_goods_write/${this.category}/${this.item.seq}${query}`
        );
      } else {
        this.$toast.default("유효하지 않은 유형입니다.");
        return false;
      }
    },
  },
  computed: {
    ...mapState("mypage", ["myInfo", "channel"]),
    ...mapState("review", ["replyListArray", "msg", "result", "limit"]),
    compareItem1() {
      if (this.item.compare_goods_seq) {
        if (this.item.compare_goods_seq.split(",")[0]) {
          return this.item.compare_goods_seq.split(",")[0];
        }
      }
      return 0;
    },
    compareItem2() {
      if (this.item.compare_goods_seq) {
        if (this.item.compare_goods_seq.split(",")[1]) {
          return this.item.compare_goods_seq.split(",")[1];
        }
      }
      return 0;
    },
    comCategory1() {
      if (this.item.compare_category) {
        if (this.item.compare_category.split(",")[0]) {
          return this.item.compare_category.split(",")[0];
        }
      }
      return 0;
    },
    comCategory2() {
      if (this.item.compare_category) {
        if (this.item.compare_category.split(",")[1]) {
          return this.item.compare_category.split(",")[1];
        }
      }
      return 0;
    },
    isReviewer() {
      if (
        this.$store.state.mypage.myInfo.is_reviewer === "Y" &&
        this.$store.state.mypage.channel.reviewer
      ) {
        return this.$store.state.mypage.myInfo.member_seq;
      }
      return 0;
    },
    isSeller() {
      if (
        this.$store.state.mypage.myInfo.is_seller === "Y" &&
        this.$store.state.mypage.channel.seller
      ) {
        return this.$store.state.mypage.myInfo.member_seq;
      }
      return 0;
    },
    pageUrl() {
      // console.log(this.nowPage);
      if (parseInt(this.item.option_category1) === 1) {
        return `${this.nowPage}goods_seq=${this.item.goods_seq}&option1=${this.item.option_category1}&option2=${this.item.option_category2}&option3=${this.item.option_category3}`;
      }
      return `${this.nowPage}option1=${this.item.option_category1}&option2=${this.item.option_category2}&option3=${this.item.option_category3}`;
    },
  },
  components: {
    ReviewSnsShare,
    ReviewReply,
    ReviewDeclaration,
  },
};
</script>

<style></style>
