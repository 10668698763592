var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"box_1"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":_vm.item.thumbnail,"alt":""},on:{"error":_vm.replaceImg,"click":function($event){return _vm.openDetail()}}}),_c('span',[_vm._v(_vm._s(_vm.index))])]),_c('div',{staticClass:"txt"},[_c('h3',{on:{"click":function($event){return _vm.openDetail()}}},[_vm._v(" "+_vm._s(_vm.item.title)+" ")]),_c('p',{on:{"click":function($event){return _vm.openDetail()}}},[_vm._v("상품명 : "+_vm._s(_vm.item.main_goods_name))])])]),_c('ul',{staticClass:"bottom"},[(parseInt(_vm.item.option_category1) !== 3)?[_c('li',[_c('router-link',{attrs:{"to":""},nativeOn:{"click":function($event){return _vm.likeCheck({
                wish_yn: _vm.item.wish_yn_reviwer,
                reviewer_seq: _vm.item.member_seq,
                review_seq: null,
                main_seq: _vm.item.main_menu_seq,
                type: 'reviewer',
              })}}},[(_vm.item.wish_yn_reviwer == 'Y')?_c('img',{attrs:{"src":require("@/assets/images/sub/like_on.png"),"alt":""}}):_c('img',{attrs:{"src":require("@/assets/images/sub/like_off.png"),"alt":""}})]),_c('router-link',{attrs:{"to":""}},[_vm._v(_vm._s(_vm.item.nickname))])],1)]:[_c('li',[_c('router-link',{attrs:{"to":""},nativeOn:{"click":function($event){return _vm.likeShop({
                wish_yn: _vm.item.mini_shop_wish,
                reviewer_seq: null,
                review_seq: _vm.item.provider_seq,
                main_seq: _vm.item.main_menu_seq,
                type: 'shop',
              })}}},[(_vm.item.mini_shop_wish == 'Y')?_c('img',{attrs:{"src":require("@/assets/images/sub/like_on.png"),"alt":""}}):_c('img',{attrs:{"src":require("@/assets/images/sub/like_off.png"),"alt":""}})]),_c('router-link',{attrs:{"to":""}},[_vm._v(_vm._s(_vm.item.nickname))])],1)],_c('li',[_vm._v("리뷰 신뢰도 "+_vm._s(_vm.rammFloat(_vm.item.trust_score)))]),_c('li',[_vm._v("제품 평점 "+_vm._s(_vm.item.main_score))]),_c('li',[_c('router-link',{attrs:{"to":"","id":("arrow_" + (_vm.item.seq))},nativeOn:{"click":function($event){return _vm.openDetail()}}},[_vm._v("∨")])],1)],2)]),_c('div',{staticClass:"box_2 detail",staticStyle:{"display":"none"},attrs:{"id":("item_detail_" + (_vm.item.seq))}},[_c('h3',[_vm._v(" "+_vm._s(_vm.item.title)+" ")]),(parseInt(_vm.item.option_category1) === 1)?_c('div',{staticClass:"top_1"},[(
          _vm.myInfo && parseInt(_vm.myInfo.member_seq) === parseInt(_vm.item.member_seq)
        )?_c('div',{staticClass:"left"},[(parseInt(_vm.item.member_seq) === parseInt(_vm.isReviewer))?[_c('router-link',{attrs:{"to":""},nativeOn:{"click":function($event){return _vm.modifyReview('normal')}}},[_vm._v("수정")]),_vm._v("  |  "),_c('router-link',{attrs:{"to":""},nativeOn:{"click":function($event){return _vm.deleteReview.apply(null, arguments)}}},[_vm._v("삭제")])]:_vm._e()],2):_vm._e(),_c('div',{staticClass:"right"},[_c('b',[_vm._v("구매자들의 리뷰 신뢰도  "+_vm._s(_vm.rammFloat(_vm.item.trust_score)))]),_c('br'),_vm._v("리뷰어의 제품 평점  "+_vm._s(_vm.item.main_score)+" ")])]):_vm._e(),(parseInt(_vm.item.option_category1) === 2)?_c('div',{staticClass:"top_2"},[(
          _vm.myInfo && parseInt(_vm.myInfo.member_seq) === parseInt(_vm.item.member_seq)
        )?_c('div',{staticClass:"left"},[(
            parseInt(_vm.item.member_seq) === parseInt(_vm.isReviewer) &&
            _vm.channel.reviewer
          )?[_c('router-link',{attrs:{"to":""},nativeOn:{"click":function($event){return _vm.modifyReview('pro')}}},[_vm._v("수정")]),_vm._v("  |  "),_c('router-link',{attrs:{"to":""},nativeOn:{"click":function($event){return _vm.deleteReview.apply(null, arguments)}}},[_vm._v("삭제")])]:_vm._e()],2):_vm._e(),_c('div',{staticClass:"right"},[_c('b',[_vm._v("구매자들의 리뷰 신뢰도  "+_vm._s(_vm.rammFloat(_vm.item.trust_score)))])])]):_vm._e(),(parseInt(_vm.item.option_category1) === 3)?_c('div',{staticClass:"top_1"},[(
          _vm.myInfo && parseInt(_vm.myInfo.member_seq) === parseInt(_vm.item.member_seq)
        )?_c('div',{staticClass:"left"},[(parseInt(_vm.item.member_seq) === parseInt(_vm.isSeller))?[_c('router-link',{attrs:{"to":""},nativeOn:{"click":function($event){return _vm.modifyReview('seller')}}},[_vm._v("수정")]),_vm._v("  |  "),_c('router-link',{attrs:{"to":""},nativeOn:{"click":function($event){return _vm.deleteReview.apply(null, arguments)}}},[_vm._v("삭제")])]:_vm._e()],2):_vm._e(),_c('div',{staticClass:"right"},[_c('b',[_vm._v("구매자들의 리뷰 신뢰도  "+_vm._s(_vm.rammFloat(_vm.item.trust_score)))]),_c('br'),_vm._v("리뷰어의 제품 평점  "+_vm._s(_vm.item.main_score)+" ")])]):_vm._e(),(parseInt(_vm.item.option_category1) == 2)?_c('div',{staticClass:"grade"},[_c('div',{staticClass:"left"},[_vm._v("리뷰어의 제품 평점")]),_c('div',{staticClass:"right"},[_c('div',[_c('span',{staticClass:"txt"},[_vm._v("A("+_vm._s(_vm.item.main_goods_name)+")")]),_c('span',{staticClass:"num"},[_vm._v(_vm._s(_vm.item.main_score))])]),_vm._l((_vm.compareGoods(_vm.item)),function(compare,index){return [_c('div',{key:index},[_c('span',{staticClass:"txt"},[_vm._v(_vm._s(parseInt(index) < 1 ? "B" : "C")+" ("+_vm._s(compare.goods_name)+")")]),_c('span',{staticClass:"num"},[_vm._v(_vm._s(compare.score))])])]})],2)]):_vm._e(),_c('div',{staticClass:"middle",domProps:{"innerHTML":_vm._s(_vm.item.content)}},[_vm._v(" "+_vm._s(_vm.item.content)+" ")]),_c('div',{staticClass:"rn_ms_empty_20"}),_c('div',{staticClass:"btn_2"},[_c('router-link',{staticClass:"left black",attrs:{"to":""},nativeOn:{"click":function($event){return _vm.likeCheck({
            wish_yn: _vm.item.wish_yn,
            review_seq: _vm.item.seq,
            reviewer_seq: null,
            main_seq: _vm.item.main_menu_seq,
            type: 'review',
          })}}},[_vm._v("♡ 이 리뷰 찜하기")]),_c('router-link',{staticClass:"right white",attrs:{"to":""},nativeOn:{"click":function($event){return _vm.openDeclaration()}}},[_vm._v("신고하기")]),_c('router-link',{staticClass:"right gray",attrs:{"to":""},nativeOn:{"click":function($event){return _vm.openPopSns()}}},[_vm._v("SNS공유하기")]),_c('ReviewSnsShare',{ref:("sns_share_" + (_vm.item.seq)),attrs:{"item":_vm.item,"nowPage":_vm.pageUrl,"id":("sns_share_" + (_vm.item.seq))}}),_c('ReviewDeclaration',{staticStyle:{"display":"none"},attrs:{"item":_vm.item,"id":("declaration_" + (_vm.item.seq))}})],1),_c('div',{staticClass:"reply_btn",on:{"click":function($event){return _vm.openReply()}}},[_c('router-link',{attrs:{"to":""}},[_vm._v("댓글 "+_vm._s(_vm.item.comment_cnt)+"개 "),_c('span',{staticClass:"arrow_reply",attrs:{"id":("arrow_reply_" + (_vm.item.seq))}},[_vm._v("∨")])])],1),_c('div',{staticClass:"rn_ms_empty_20"}),_c('ReviewReply',{ref:("reply_view_" + (_vm.item.seq)),staticClass:"reply_list_box",staticStyle:{"display":"none"},attrs:{"id":("reply_view_" + (_vm.item.seq)),"item":_vm.item,"replyListArray":_vm.replyListArray,"page":parseInt(_vm.replyPage)},on:{"setSort":_vm.setSort,"getReplyList":_vm.getReplyList}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }